import React, { useEffect, useState } from "react";
import { Navbar, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoginModal from "./LoginModal";

const Header = () => {
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(false);
  const init = new Date()
  const [date, setDate] = useState(init)

  const tick = () => {
    setDate(new Date())
  }

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000)
    return () => {
      clearInterval(timerID)
    }
  }, [])

  return (
    <React.Fragment>
      <LoginModal 
      show={show}
      setShow={setShow}
      />
      <Row className="mx-0 align-items-center">
        <Col md="9" className="d-none d-md-block py-2">
          {date.toDateString() + " " + date.toLocaleTimeString()}
        </Col>
        <Col md="3 text-center">
          <Row className="selectRow">
            <Col className={active ? "selectSchool" : "selectSchoolActive"} onClick={() => setActive(false)}>School</Col>
            <Col className={active ? "selectInstituteActive" : "selectInstitute"} onClick={() => setActive(true)}>Institute</Col>
          </Row>
        </Col>
      </Row>

      {/* <div className="mx-0 d-flex schins">
        <div className="schoolActive">
          School
        </div>
        <div className="institueFFF">
          Institute
        </div>
      </div> */}
      {/* <Row className="mx-0">
        <Col>

        </Col>
        <Col className="text-end p-0">
          <div className="si">
            <div className="sactive">School</div>
            <div className="insFFF">Institute</div>
          </div>
        </Col>
      </Row> */}
      <Navbar className="headerbg" expand="lg">
        <Navbar.Brand >
          <Link to="/"><img src='/assets/acheiversLogo.jpeg' alt="Acheivers" className="logo" /></Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Link to="/about" className="headerLink">About</Link>
            <Link to="/courses" className="headerLink">Courses</Link>
            <Link to="/registration" className="headerLink">Registration</Link>
            <Link to="/contact" className="headerLink">Contact Us</Link>
            <div onClick={()=>setShow(true)} className="headerLink mouse">Login</div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </React.Fragment>
  )
}

export default Header;