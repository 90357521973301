import React from 'react'
import { Col, Container, Form, Row, Table } from 'react-bootstrap'

const ViewReg = () => {
    return (
        <React.Fragment>
            <Container>
                <h3 className='my-5 text-center'>Online Registration Application Form</h3>
                {/* <Row className='border border-dark mx-0 mb-3'>
                    <Col xs="9">
                        <Row>
                            <Col xs="6">
                                Application Number
                            </Col>
                            <Col xs="6 border-start border-dark">
                                Application Date &amp; Time
                            </Col>
                            <Col xs="6 border-top border-dark">
                                XYZ
                            </Col>
                            <Col xs="6 border-start border-top border-dark">
                                01-01-0000 00:00 PM
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="3 border-start border-dark py-2">
                        <img src='/assets/teacher1.png' alt="" height={200} width={200}/>
                    </Col>
                </Row> */}

                <Table bordered className='border-dark'>
                    <tbody className='text-center'>
                        <tr>
                            <td className='h40'>Application Number</td>
                            <td className='h40'>Application Date &amp; Time</td>
                            <td rowSpan={2}><img src='/assets/teacher1.png' alt="" className='my-2' height={200} width={200}/></td>
                        </tr>
                        <tr >
                            <td className='pt-5'>XYZ</td>
                            <td className='pt-5'>01-01-0000 00:00 PM</td>
                        </tr>
                    </tbody>
                </Table>
                <h6>1. Registration Details</h6>
                <Row className='border border-dark mx-0 mb-3'>
                    <Col xs="3">
                        Registration For Course
                    </Col>
                    <Col xs="9 border-start border-dark">
                        1st Class
                    </Col>
                </Row>

                <h6>2. Applicant's Details</h6>
                <Row className='border border-dark mx-0 mb-3'>
                    <Col xs="3">
                        Applicant's Full Name
                    </Col>
                    <Col xs="9 border-start border-dark">
                        XYZ
                    </Col>
                    <Col xs="3 border-top border-dark">
                        Father's Name
                    </Col>
                    <Col xs="9 border-start border-top  border-dark">
                        XYZ
                    </Col>
                    <Col xs="3 border-top border-dark">
                        Mother's Name
                    </Col>
                    <Col xs="9 border-start border-top  border-dark">
                        XYZ
                    </Col>
                    <Col xs="3 border-top border-dark">
                        Gender
                    </Col>
                    <Col xs="3 border-start border-top  border-dark">
                        XYZ
                    </Col>
                    <Col xs="3 border-start border-top border-dark">
                        Date of Birth
                    </Col>
                    <Col xs="3 border-start border-top  border-dark">
                        01-01-0000
                    </Col>
                    <Col xs="3 border-top border-dark">
                        Category
                    </Col>
                    <Col xs="3 border-start border-top  border-dark">
                        XYZ
                    </Col>
                    <Col xs="3 border-start border-top border-dark">
                        Sub Category
                    </Col>
                    <Col xs="3 border-start border-top  border-dark">
                        XYZ
                    </Col>
                    <Col xs="3 border-top border-dark">
                        Religion
                    </Col>
                    <Col xs="9 border-start border-top  border-dark">
                        XYZ
                    </Col>
                </Row>
                <h6>3. Contact Details</h6>
                <Row className='border border-dark mx-0 mb-3'>
                    <Col xs="3 border-dark">
                        Mobile Number
                    </Col>
                    <Col xs="3 border-start  border-dark">
                        1234567890
                    </Col>
                    <Col xs="3 border-start border-dark">
                        Alternate Mobile Number
                    </Col>
                    <Col xs="3 border-start  border-dark">
                        1234567890
                    </Col>
                    <Col xs="3 border-top border-dark">
                        Email Address
                    </Col>
                    <Col xs="9 border-start border-top border-dark">
                        xyz@mail.com
                    </Col>
                    <Col xs="3 border-top border-dark" >
                        Address
                    </Col>
                    <Col xs="9 border-start border-top border-dark">
                        XYZ
                    </Col>
                    <Col xs="3 border-top border-dark">
                        City Name
                    </Col>
                    <Col xs="3 border-start border-top  border-dark">
                        XYZ
                    </Col>
                    <Col xs="3 border-start border-top border-dark">
                        District
                    </Col>
                    <Col xs="3 border-start border-top  border-dark">
                        XYZ
                    </Col>
                    <Col xs="3 border-top border-dark">
                        State
                    </Col>
                    <Col xs="3 border-start border-top  border-dark">
                        XYZ
                    </Col>
                    <Col xs="3 border-top border-start  border-dark">
                        Pin Code
                    </Col>
                    <Col xs="3 border-start border-top  border-dark">
                        XYZ
                    </Col>
                </Row>

                <h6>4. Educational Details</h6>
                <Row className='border border-dark mx-0 mb-3'>
                    <Col xs="3">
                        Course Name
                    </Col>
                    <Col xs="9 border-start  border-dark">
                        1st  Class
                    </Col>
                    <Col xs="3 border-top border-dark">
                        School Name
                    </Col>
                    <Col xs="9 border-start border-top border-dark">
                        XYZ
                    </Col>
                    <Col xs="3 border-top border-dark" >
                        Year
                    </Col>
                    <Col xs="9 border-start border-top border-dark">
                        2022
                    </Col>
                    <Col xs="3 border-top border-dark">
                        Obtain Mark
                    </Col>
                    <Col xs="3 border-start border-top  border-dark">
                        500
                    </Col>
                    <Col xs="3 border-start border-top border-dark">
                        Total Mark
                    </Col>
                    <Col xs="3 border-start border-top  border-dark">
                        600
                    </Col>
                    <Col xs="3 border-top border-dark">
                        Per
                    </Col>
                    <Col xs="9 border-start border-top  border-dark">
                        83.33
                    </Col>
                </Row>
                <Row className='mt-3 mb-5'>
                    <Form.Group as={Col} lg="4" xs="6 mt-4 text-center" controlId="validationCustom03">
                        <img src={""} alt="" className='isPhoto' />
                    </Form.Group>
                    <Form.Group as={Col} lg="4" xs="6 mt-4 text-center" controlId="validationCustom03">
                        <img src={""} alt="" className='isPhoto' />
                    </Form.Group>

                    <Form.Group as={Col} lg="4" xs="6 mt-4 text-center" controlId="validationCustom03">
                        <img src={""} alt="" className='isPhoto' />
                    </Form.Group>
                </Row>
                <div className='text-center mb-3'>
                    <button className='contactBtn my-3 w-auto px-5 fw-bold'>Edit</button>
                    <button className='contactBtn my-3 w-auto px-5 fw-bold ms-4'>Confirm</button>
                </div>
            </Container>
        </React.Fragment >
    )
}

export default ViewReg