import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const Courses = () => {
    const navigate = useNavigate();
    const courses = [
        {
            src: "/assets/slider1.webp",
            courseName: "First Class",
            courseDesc: "Separated they live in. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country"
        },
        {
            src: "/assets/slider2.webp",
            courseName: "Second Class",
            courseDesc: "Separated they live in. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country"
        },
        {
            src: "/assets/slider1.webp",
            courseName: "Third Class",
            courseDesc: "Separated they live in. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country"
        },
        {
            src: "/assets/slider2.webp",
            courseName: "Fourth Class",
            courseDesc: "Separated they live in. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country"
        },
    ]
    return (
        <React.Fragment>
            <div className='bggray'>
                <Container>
                    <h1 className='main_heading'>Courses</h1>
                </Container>
            </div>
            <Container>
                {/* <h1 className="main_heading">Courses</h1> */}
                <Row className='mb-5 mt-2'>
                    {courses.map((item, index) =>
                        <Col key={index} lg={3} md={4} sm={6} className="mt-4">
                            <Card>
                                <Card.Img className='courseImg mouse' variant="top" src={item.src} onClick={()=>navigate("/courses-"+item.courseName.replace(/\s/g, '-'))}/>
                                <Card.Body className='mouse' onClick={()=>navigate("/courses-"+item.courseName.toLocaleLowerCase().replace(/\s/g, '-'))}>
                                    <h4 className='mutedClr fw-bold'>{item.courseName}</h4>
                                    <Card.Text className='courseDesc'>
                                        {item.courseDesc}
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className='bg-transparent'>
                                    <Row className='mx-0 align-items-center'>
                                        <Col className='ps-0'>
                                            <button className='chatBtn rounded-pill'>Chat Now</button>
                                        </Col>
                                        <Col className='pe-0 text-end'>
                                            <button className='subscribeBtn rounded-pill'>Apply Now</button>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </Col>
                    )}
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default Courses