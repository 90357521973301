import React from 'react'
import { Carousel, Col, Container, Row } from 'react-bootstrap'
import { FaBook, FaGraduationCap, FaUser } from 'react-icons/fa'

const Home = () => {

  const latestNews = [
    {
      src: "/assets/news1.jpeg",
      title: "Summer Course Starts From 1st June",
      date: "March 14, 2022",
      description: "Dimply dummy text of the printing and typesetting industry. Lorem Ipsum has been"
    },
    {
      src: "/assets/news1.jpeg",
      title: "Summer Course Starts From 1st June",
      date: "March 14, 2022",
      description: "Dimply dummy text of the printing and typesetting industry. Lorem Ipsum has been printing and typesetting industry. Lorem Ipsum has been"
    },
    {
      src: "/assets/news1.jpeg",
      title: "Summer Course Starts From 1st June",
      date: "March 14, 2022",
      description: "Dimply dummy text of the printing and typesetting industry. Lorem Ipsum has been printing and typesetting industry. Lorem Ipsum has been"
    },
  ]

  const events = [
    {
      title: "SAT Implementation Workshops November 2026",
      date: "May 2022",
      time: "11:00 - 13:30",
      description: "Bimply dummy text of the printing and typesetting istryrem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer.when an unknown printer took a galley of type and scrambled"
    },
    {
      title: "SAT Implementation Workshops November 2026",
      date: "May 2022",
      time: "11:00 - 13:30",
      description: "Bimply dummy text of the printing and typesetting istryrem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer.when an unknown printer took a galley of type and scrambled"
    },
  ]
  const slider = [
    {
      src: "/assets/slider1.jpeg",
      title: "Summer Course Starts From 1st June",
      date: "March 14, 2022",
      description: "Dimply dummy text of the printing and typesetting industry. Lorem Ipsum has been"
    },
    {
      src: "/assets/slider2.jpeg",
      title: "Summer Course Starts From 1st June",
      date: "March 14, 2022",
      description: "Dimply dummy text of the printing and typesetting industry. Lorem Ipsum has been printing and typesetting industry. Lorem Ipsum has been"
    },
    {
      src: "/assets/slider3.jpeg",
      title: "Summer Course Starts From 1st June",
      date: "March 14, 2022",
      description: "Dimply dummy text of the printing and typesetting industry. Lorem Ipsum has been printing and typesetting industry. Lorem Ipsum has been"
    },
    {
      src: "/assets/slider4.jpeg",
      title: "Summer Course Starts From 1st June",
      date: "March 14, 2022",
      description: "Dimply dummy text of the printing and typesetting industry. Lorem Ipsum has been printing and typesetting industry. Lorem Ipsum has been"
    },
  ]
  return (
    <React.Fragment>
      <Carousel fade>
        {slider.map((slide, index) => 
        <Carousel.Item key={index}>
          <img
            className="d-block w-100 sliderImg"
            src={slide.src}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>{slide.title}</h3>
            <p>{slide.description}</p>
          </Carousel.Caption>
        </Carousel.Item>
        )}
      </Carousel>

      {/* --------------------------Welcome-------------------- */}
      <Container className="my-5 text-center">
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <h1 className='redClr fw-bold'>Welcome to Achiever's School</h1>
            <p className='my-3 text-secondary'>
              Tmply dummy text of the printing and typesetting industry. Lorem Ipsum has been
              theindustry's standard dummy text ever since the 1500s, when an unknown printer took.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="px-4 mt-5 tw_center">
            <div className='welcome-icon'>
              <FaGraduationCap />
            </div>
            <h3 className='welcome-heading'>Scholarship Facility</h3>
            <p>Dorem Ipsum has been the industry's standard dummy text ever since the en an unknown printer galley dear.</p>
          </Col>
          <Col md={4} className="px-4 mt-5 tw_center">
            <div className='welcome-icon'>
              <FaUser />
            </div>
            <h3 className='welcome-heading'>Skilled Lecturers</h3>
            <p>Dorem Ipsum has been the industry's standard dummy text ever since the en an unknown printer galley dear.</p>
          </Col>
          <Col md={4} className="px-4 mt-5 tw_center">
            <div className='welcome-icon'>
              <FaBook />
            </div>
            <h3 className='welcome-heading'>Book Library &amp; Store</h3>
            <p>Dorem Ipsum has been the industry's standard dummy text ever since the en an unknown printer galley dear.</p>
          </Col>
        </Row>
      </Container>

      {/* --------------------------News Events-------------------- */}
      <Container className=''>
        <Row>
          <Col md="6 mt-5">
            <h3>Latest News</h3>
            {latestNews.map((item, index) =>
              <div className='d-flex boxShadow p-2 mt-3 mouseOver' key={index}>
                <img src={item.src} className="newImg" alt={item.title} />
                <div className='ms-3'>
                  <h5>{item.title}</h5>
                  <h6 className='orangeClr'>{item.date}</h6>
                  <p className='mb-0 line_clamp text-secondary'>{item.description}</p>
                </div>
              </div>
            )}
            <button className='viewAllBtn mt-3 float-end'>View All</button>
          </Col>
          <Col md="6 mt-5">
            <h3>Events / Announcement </h3>
            {events.map((item, index) =>
              <div className='d-flex boxShadow p-2 my-3 mouseOver' key={index}>
                <div className="rtin-calender">
                  <h3>20</h3>
                  <p>Oct</p>
                  <div className='eventYear'>2026</div>
                </div>
                <div className='ms-3'>
                  <h5>{item.title}</h5>
                  <p className='mb-0 line_clamp4 text-secondary mt-3 mb-3'>{item.description}</p>
                  <div className='blueClr mt-2 fw-bold'>{item.time}</div>
                </div>
              </div>
            )}
            <button className='viewAllBtn  float-end'>View All</button>
          </Col>
        </Row>
      </Container>

      {/* --------------------------Counter-------------------- */}
      <div className='counterBg mt-5'>
        <Container>
          <Row className='text-white'>
            <Col md="3 my-4" sm="6" >
              <Row className='align-items-center'>
                <Col md="4 tw_center px-0">
                  <h1 className='counterNo'>20</h1>
                </Col>
                <Col md="8">
                  <div className='counterText'>
                    PROFESSIONAL INSTRUCTORS
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md="3 my-4" sm="6">
              <Row className='align-items-center'>
                <Col md="4 tw_center px-0">
                  <h1 className='counterNo'>10</h1>
                </Col>
                <Col md="8">
                  <div className='counterText'>
                    NEW COURSES EVERY YEAR
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md="3 my-4" sm="6">
              <Row className='align-items-center'>
                <Col md="4 tw_center px-0">
                  <h1 className='counterNo'>25</h1>
                </Col>
                <Col md="8">
                  <div className='counterText'>
                    LIVE SESSIONS EVERY MONTH
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md="3 my-4" sm="6">
              <Row className='align-items-center'>
                <Col md="6 tw_center px-0">
                  <h1 className='counterNo'>270</h1>
                </Col>
                <Col md="6">
                  <div className='counterText'>
                    REGISTERED STUDENTS
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      {/* --------------------------Counter-------------------- */}
      <Container className="my-5 text-center">
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <h1 className='redClr fw-bold'>What Parents Says About Us</h1>
            <p className='my-3 text-secondary'>
              Tmply dummy text of the printing and typesetting industry. Lorem Ipsum has been
              theindustry's standard dummy text ever since the 1500s, when an unknown printer took.
            </p>
          </Col>
          <Col md={{ span: 8, offset: 2 }}>
            <Carousel variant="dark" className='mt-4' indicators={false}>
              <Carousel.Item >
                <img
                  className="me-4 rounded-circle"
                  src="/assets/slider1.webp"
                  alt="First slide"
                  height="100"
                  width="100"
                />
                <div className='mt-4'>
                  <h3>First slide label</h3>
                  <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </div>
              </Carousel.Item>
              <Carousel.Item >
                <img
                  className="me-4 rounded-circle"
                  src="/assets/slider1.webp"
                  alt="First slide"
                  height="100"
                  width="100"
                />
                <div className='mt-4'>
                  <h3>Second slide label</h3>
                  <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </div>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>

      </Container>
    </React.Fragment>
  )
}

export default Home