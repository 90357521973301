import React from 'react'
import "./App.css"
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Header from './college/components/Header'
import Home from './college/pages/Home'
import Footer from './college/components/Footer'
import About from './college/pages/About'
import Courses from './college/pages/Courses'
import CourseDetails from './college/pages/CourseDetails'
import Contact from './college/pages/Contact'
import Registration from './college/pages/Registration'
import ViewReg from './college/pages/ViewReg'
import ParentDashboard from './college/parent/ParentDashboard'
import ParentHeader from './college/parent/ParentHeader'
import StudentProfile from './college/parent/StudentProfile'
import StudentResult from './college/parent/StudentResult'

const App = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        {localStorage.getItem('userId') ? <ParentHeader />: <Header />}
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/courses' element={<Courses />} />
          <Route exact path='/courses-:any' element={<CourseDetails />} />
          <Route exact path='/registration' element={<Registration />} />
          <Route exact path='/viewform' element={<ViewReg />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/dashboard' element={<ParentDashboard />} />
          <Route exact path='/profile' element={<StudentProfile />} />
          <Route exact path='/results' element={<StudentResult />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </React.Fragment>
  )
}

export default App