import React, { useState } from 'react'
import { Form, InputGroup, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const LoginModal = ({ show, setShow }) => {
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            localStorage.setItem('userId', "1234567890");
            setShow(false);
            navigate('/dashboard');
        }

        setValidated(true);
    };
    return (
        <React.Fragment>
            <Modal show={show} onHide={() => setShow(false)} centered>
                <Modal.Body>
                    <h1 className='text-center mt-2 mb-4'>Login</h1>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group controlId="validationCustom01" className='mb-3'>
                            <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">+91</InputGroup.Text>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Enter Mobile Number "
                                    defaultValue={1234567890}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="validationCustom02" className='mb-3'>
                            <Form.Control
                                required
                                type="password"
                                placeholder="Enter Password"
                                defaultValue={123456}
                            />
                        </Form.Group>
                        <div className='text-danger text-end text-decoration-underline mouse'>Forget Password?</div>
                        <div className='text-center'>
                            <button className='contactBtn my-3 w-auto px-5 fw-bold'>Login</button>
                            <div onClick={() => { navigate("/registration"); setShow(false) }} className=''>
                                Don’t have an account? <span className='fw-bold lightBlueClr mouse'>Register Now</span>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

        </React.Fragment>
    )
}

export default LoginModal