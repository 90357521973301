import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BsCheckCircle } from 'react-icons/bs'
import { FaRegClock, FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaFileAlt } from 'react-icons/fa'

const CourseDetails = () => {
    const [steps, setSteps] = useState(0);
    const syllabus = [
        {
            lectureName: "Lecture 1",
            lectureTitle: "Lesson with Text",
            lectureDuration: "1:30",
        },
        {
            lectureName: "Lecture 2",
            lectureTitle: "Lesson with Text",
            lectureDuration: "1:30",
        },
        {
            lectureName: "Lecture 3",
            lectureTitle: "Lesson with Text",
            lectureDuration: "1:30",
        },
    ]
    return (
        <React.Fragment>
            <div className='bggray'>
                <Container>
                    <h1 className='main_heading'>Courses</h1>
                </Container>
                <Container>
                    <Row>
                        <Col lg={9} className="mb-5">
                            <div className='course_details'>
                                <img src="/assets/slider1.webp" alt="" className='courseDImg' />
                                <Row className='text-center my-4 mx-0'>
                                    <Col xs="3 px-0" className={steps === 0 ? 'cd_steps_active' : 'cd_steps border-end border-light'} onClick={() => setSteps(0)}>
                                        Overview
                                    </Col>

                                    <Col xs="3 px-0" className={steps === 1 ? 'cd_steps_active' : 'cd_steps border-end border-light'} onClick={() => setSteps(1)}>
                                        Syllabus
                                    </Col>

                                    <Col xs="3 px-0" className={steps === 2 ? 'cd_steps_active' : 'cd_steps border-end border-light'} onClick={() => setSteps(2)}>
                                        Instructor
                                    </Col>

                                    <Col xs="3 px-0" className={steps === 3 ? 'cd_steps_active' : 'cd_steps'} onClick={() => setSteps(3)}>
                                        Demo
                                    </Col>
                                </Row>
                                {steps === 0 && <>
                                    <Row className='mx-0'>
                                        <Col lg="4 mt-3" md="6">
                                            <BsCheckCircle className='mb-1' /> Instructor : Tom Steven
                                        </Col>
                                        <Col lg="4 mt-3" md="6">
                                            <BsCheckCircle className='mb-1' /> Lectures : 12
                                        </Col>
                                        <Col lg="4 mt-3" md="6">
                                            <BsCheckCircle className='mb-1' /> Fee : 3000/-
                                        </Col>
                                        <Col lg="4 mt-3" md="6">
                                            <BsCheckCircle className='mb-1' /> Duration : 1 Year
                                        </Col>
                                    </Row>
                                    <p className='border-top mt-4 pt-3 lh_1_8 text_justify'>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type
                                        specimen book. It has survived not only five centuries, but also the leap into
                                        electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy
                                        text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s, when an unknown printer took a galley of
                                        type and scrambled it to make a type specimen book. It has survived not only five
                                        centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                        been the industry's standard dummy text ever since the 1500s, when an unknown printer took
                                        a galley of type and scrambled it to make a type specimen book. It has survived not only
                                        five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                    </p>
                                </>
                                }
                                {steps === 1 && <div className='pt-3'>
                                    {syllabus.map((item, index) =>
                                        <Row key={index} className='mx-0 cdSyllabus border-bottom border-light align-items-center'>
                                            <Col sm="3">
                                                <FaFileAlt className='mb-1' /> {item.lectureName}
                                            </Col>
                                            <Col sm="7">
                                                <h6 className='mb-0'>{item.lectureTitle}</h6>
                                            </Col>
                                            <Col sm="2">
                                                <FaRegClock /> {item.lectureDuration}
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                                }
                                {steps === 2 && <>
                                    <Row className='mx-0'>
                                        <Col lg="3 mt-3">
                                            <img src='/assets/vijaysir.jpeg' alt='Director' className='rounded-circle w-100' />

                                        </Col>
                                        <Col lg="9 mt-3">
                                            <h2>Tom Steven</h2>
                                            <h6>Data Scientist</h6>
                                            <p className='text_justify'>
                                                I've spent the last few years teaching people to program at 2 different immersive
                                                bootcamps where I've helped hundreds of people become web developers and change
                                                their lives. My graduates work at companies like Google, Salesforce, and Square.
                                            </p>
                                            <a href="/">
                                                <FaFacebookF className='cdSocialIcon' />
                                            </a>
                                            <a href='/' >
                                                <FaInstagram className='cdSocialIcon mx-2' />
                                            </a>
                                            <a href='/'>
                                                <FaTwitter className='cdSocialIcon' />
                                            </a>
                                            <a href='/'>
                                                <FaLinkedinIn className='cdSocialIcon ms-2' />
                                            </a>
                                        </Col>
                                    </Row>
                                </>
                                }
                                {steps === 3 && <>
                                </>
                                }
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className='course_details'>
                                <button className='subscribeBtn'>Register Now</button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CourseDetails