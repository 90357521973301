import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const ParentDashboard = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>

      <Container className='mb-4'>
        <Row>
          <Col md="3 mt-4 mouse" onClick={() => navigate("/profile")}>
            <div className='border rounded-3'>
              <img src='/assets/teacher1.png' alt='Parent Dashboard' className='w-100' />
              <div className='p-2'>
                <h5>Pradeep Kumar</h5>
                <h6>1st Class</h6>
                <h6>#001</h6>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default ParentDashboard