import React from 'react'
import { Badge, Nav, Navbar } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'

const ParentHeader = () => {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <Navbar className="headerbg" expand="lg">
                <Navbar.Brand >
                    <Link to="/"><img src='/assets/acheiversLogo.jpeg' alt="Acheivers" className="logo" /></Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Link to="/dashboard" className="headerLink">Dashboard</Link>
                        <Link to="/profile" className="headerLink">Profile</Link>
                        <Link to="/results" className="headerLink">Results</Link>
                        <Link to="/achievments" className="headerLink">Achievments</Link>
                        <Link to="/notifications" className="headerLink">Notifications <Badge pill bg="warning" text="dark">2</Badge></Link>
                    </Nav>
                        <div onClick={()=>{localStorage.clear(); navigate("/")}} className="headerLink mouse">Logout</div>
                </Navbar.Collapse>
            </Navbar>
        </React.Fragment>
    )
}

export default ParentHeader