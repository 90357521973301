import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaChevronDown, FaChevronRight, FaEnvelope, FaFacebookF, FaInstagram, FaPhoneAlt, FaTwitter, FaWhatsapp } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Footer = () => {
  const [school, setSchool] = useState(false);
  const [links, setLinks] = useState(false);
  return (
    <React.Fragment>
      <div className='bg-dark text-white'>
        <Container className='d-none d-md-block pt-2'>
          <Row>
            <Col md="3 my-4" sm="6" >
              <img src='/assets/acheiversLogo.jpeg' alt='Achivers' className='achiversLogo' />
              <p className='mt-3'>
                <FaPhoneAlt className='me-3' />
                <a href='tel:+918888888888' className='text-white text-decoration-none'>+91 8888888888</a>
              </p>
              <p className='mt-3'>
                <FaEnvelope className='me-3' />
                <a href='mailto:contact@achiversguru.in' className='text-white text-decoration-none'>contact@achiversguru.in</a>
              </p>
              <p className='mt-5'>
                <a href='https://facebook.com' className='thim_social thim_socialFa'>
                  <FaFacebookF className='thim_socialFa' />
                </a>
                <a href='https://facebook.com' className='thim_social thim_socialTw'>
                  <FaTwitter className='thim_socialTw' />
                </a>
                <a href='https://facebook.com' className='thim_social thim_socialIn'>
                  <FaInstagram className='thim_socialIn' />
                </a>
                <a href='https://facebook.com' className='thim_social thim_socialWa'>
                  <FaWhatsapp className='thim_socialWa' />
                </a>
              </p>
            </Col>

            <Col md="3 my-4" sm="6" >
              <h4 className='mb-3 mouse'>School</h4>

              <p>
                <Link to="/" className='footerLink' >
                  Admissions
                </Link>
              </p>
              <p>
                <Link to="/" className='footerLink' >
                  Our Staff
                </Link>
              </p>
              <p>
                <Link to="/" className='footerLink' >
                  About Us
                </Link>
              </p>
              <p>
                <Link to="/" className='footerLink'>
                  Contact
                </Link>
              </p>
              <p>
                <Link to="/" className='footerLink'>
                  Become a Teacher
                </Link>
              </p>
            </Col>

            <Col md="3 my-4" sm="6" >
              <h4 className='mb-3'>Links</h4>
              <p>
                <Link to="/" className='footerLink' >
                  School Fees
                </Link>
              </p>
              <p>
                <Link to="/" className='footerLink' >
                  Courses
                </Link>
              </p>
              <p>
                <Link to="/" className='footerLink' >
                  Events
                </Link>
              </p>
              <p>
                <Link to="/" className='footerLink' >
                  Gallery
                </Link>
              </p>
              <p>
                <Link to="/" className='footerLink'>
                  FAQs
                </Link>
              </p>
            </Col>

            <Col md="3 my-4" sm="6" >
              <h4 className='mb-3'>Newsletter</h4>
              <p>
                Fusce varittus, dolor tempor interdum tristiquei bibendum
              </p>
              <p className='my-4'>
                <input type="email" className='form-control bg-transparent text-white' placeholder='Enter your email' />
              </p>
              <p>
                <button className='subscribeBtn'>Subscribe Now</button>
              </p>
            </Col>
          </Row>
        </Container>
        <Container className='d-block d-md-none'>
          <Row>
            <Col md="6 my-4" >
              <h1 className='text-center mb-4'>Join Us</h1>
              <Row>
                <Col>
                  <button className='bg-transparent text-white w-100 border border-success rounded-3 py-1'>Admission</button>
                </Col>
                <Col>
                  <button className='bg-transparent text-white w-100 border border-danger rounded-3 py-1'>Become a Teacher</button>
                </Col>
              </Row>

            </Col>

            <Col md="6 mb-4 border-top border-bottom border-secondary pt-3">
              <h4 className='mb-3 mouse' onClick={() => { setSchool(!school); setLinks(false) }}>School
                <span className='float-end'>
                  {school ? <FaChevronDown /> : <FaChevronRight />}
                </span>
              </h4>
              {school &&
                <>
                  <p>
                    <Link to="/" className='footerLink' >
                      Admissions
                    </Link>
                  </p>
                  <p>
                    <Link to="/" className='footerLink' >
                      Our Staff
                    </Link>
                  </p>
                  <p>
                    <Link to="/" className='footerLink' >
                      About Us
                    </Link>
                  </p>
                  <p>
                    <Link to="/" className='footerLink'>
                      Contact
                    </Link>
                  </p>
                  <p>
                    <Link to="/" className='footerLink'>
                      Become a Teacher
                    </Link>
                  </p>
                </>
              }
            </Col>

            <Col md="6 mb-4 border-bottom border-secondary" >
              <h4 className='mb-3 mouse' onClick={() => { setLinks(!links); setSchool(false) }}>
                Links
                <span className='float-end'>
                  {links ? <FaChevronDown /> : <FaChevronRight />}
                </span>
              </h4>
              {links &&
                <>
                  <p>
                    <Link to="/" className='footerLink' >
                      School Fees
                    </Link>
                  </p>
                  <p>
                    <Link to="/" className='footerLink' >
                      Courses
                    </Link>
                  </p>
                  <p>
                    <Link to="/" className='footerLink' >
                      Events
                    </Link>
                  </p>
                  <p>
                    <Link to="/" className='footerLink' >
                      Gallery
                    </Link>
                  </p>
                  <p>
                    <Link to="/" className='footerLink'>
                      FAQs
                    </Link>
                  </p>
                </>}
            </Col>

            <Col md="12 my-4 text-center ">
              <h4 className='mb-4'>Follow Us</h4>
              <a href='https://facebook.com' className='thim_social thim_socialFa'>
                <FaFacebookF className='thim_socialFa' />
              </a>
              <a href='https://facebook.com' className='thim_social thim_socialTw ms-3'>
                <FaTwitter className='thim_socialTw' />
              </a>
              <a href='https://facebook.com' className='thim_social thim_socialIn ms-3'>
                <FaInstagram className='thim_socialIn' />
              </a>
              <a href='https://facebook.com' className='thim_social thim_socialWa ms-3'>
                <FaWhatsapp className='thim_socialWa' />
              </a>
            </Col>

            <Col md="6 mb-4 text-center border-top border-secondary pt-4">
              <h4 className='mb-3'>Newsletter</h4>
              <p>
                Fusce varittus, dolor tempor interdum tristiquei bibendum
              </p>
              <p className='my-4'>
                <input type="email" className='form-control bg-transparent text-white' placeholder='Enter your email' />
              </p>
              <p>
                <button className='subscribeBtn'>Subscribe Now</button>
              </p>
            </Col>
          </Row>
        </Container>
        <div className='border-top border-secondary'>
          <Container className='py-2 text-center'>
            &copy; {new Date().getFullYear()} Achivers School. All Rights Reserved
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Footer