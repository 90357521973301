import React, { useState } from 'react'
import { Col, Container, Form, InputGroup, Row, } from 'react-bootstrap';

const Registration = () => {
    const [formData, setFormData] = useState({
        marksheet: "",
        photo: "",
        sign: "",
        thumb: "",
        checkFiles: false,
    })
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false || formData.marksheet || formData.photo || formData.sign || formData.thumb) {
            event.preventDefault();
            event.stopPropagation();
            setFormData({
                ...formData,
                checkFiles: true
            });
        }
        setValidated(true);
    };

    console.log(formData)

    return (
        <React.Fragment>
            <div className='bggray pb-5'>
                <Container>
                    <h1 className='main_heading'>Registration Form</h1>
                </Container>
                <Container className='regP border'>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className='bgOrangeClr fw-bold p-2 text-white'>
                            Course Details
                        </div>
                        <Row className="mb-3 align-items-center">
                            <Form.Group as={Col} md="4 mt-4" controlId="validationCustom01">
                                <Form.Label>Registration For Course</Form.Label>
                                <Form.Select aria-label="Default select example" required>
                                    <option value="">--Select Course--</option>
                                    <option value="1st Class">1st Class</option>
                                    <option value="2nd Class">2nd Class</option>
                                </Form.Select>
                            </Form.Group>
                        </Row>

                        <div className='bgOrangeClr fw-bold mt-5 p-2 text-white'>
                        Applicant's Details
                        </div>
                        <Row className="mb-3 align-items-center">
                            <Form.Group as={Col} md="4 mt-4" controlId="validationCustom01">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="First Name"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4 mt-4" controlId="validationCustom02">
                                <Form.Label>Middle Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Middle Name"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4 mt-4" controlId="validationCustom03">
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Last Name"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4 mt-4" controlId="validationCustom03">
                                <Form.Label>Father's Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Father's Name"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4 mt-4" controlId="validationCustom03">
                                <Form.Label>Mother's Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Mother's Name"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4 mt-4 " controlId="validationCustom03">
                                <Form.Label>Gender</Form.Label>
                                <div className='d-flex'>
                                    <Form.Check
                                        required
                                        type="radio"
                                        label="Male"
                                        name="Gender"
                                    />
                                    <Form.Check
                                        required
                                        type="radio"
                                        label="Female"
                                        name="Gender"
                                        className='ms-3'
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="4 mt-4" controlId="validationCustom03">
                                <Form.Label>Date Of Birth</Form.Label>
                                <Form.Control
                                    required
                                    type="date"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4 mt-4" controlId="validationCustom03">
                                <Form.Label>Category</Form.Label>
                                <Form.Select aria-label="Default select example" required>
                                    <option value="">--Select--</option>
                                    <option value="General">General</option>
                                    <option value="Scheduled Caste">Scheduled Caste</option>
                                    <option value="Scheduled Tribe">Scheduled Tribe</option>
                                    <option value="Other Backward Class">Other Backward Class</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} md="4 mt-4" controlId="validationCustom03">
                                <Form.Label>Sub Category</Form.Label>
                                <Form.Control 
                                    required
                                    type="text"
                                    placeholder="Sub Category"
                                />
                            </Form.Group>

                            <Form.Group as={Col} md="4 mt-4" controlId="validationCustom03">
                                <Form.Label>Religion</Form.Label>
                                <Form.Control 
                                    required
                                    type="text"
                                    placeholder="Religion"
                                />
                            </Form.Group>
                            
                        </Row>

                        <div className='bgOrangeClr fw-bold p-2 text-white mt-5'>
                        Contact Details 
                        </div>
                        <Row className="mb-3 align-items-center">
                            <Form.Group as={Col} md="4 mt-4" controlId="validationCustom01">
                                <Form.Label>Mobile Number</Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">+91</InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        placeholder="Mobile Number"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="4 mt-4" controlId="validationCustom01">
                                <Form.Label>Alternate Mobile Number (Optional)</Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">+91</InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        placeholder="Alternate Mobile Number"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="4 mt-4" controlId="validationCustom02">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="Email Address"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="8 mt-4" controlId="validationCustom03">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Address"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4 mt-4" controlId="validationCustom03">
                                <Form.Label>Pincode</Form.Label>
                                <Form.Control
                                    required
                                    type="number"
                                    placeholder="Pincode"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4 mt-4" controlId="validationCustom03">
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="City"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4 mt-4" controlId="validationCustom03">
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="State"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4 mt-4" controlId="validationCustom03">
                                <Form.Label>Country</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Country"
                                />
                            </Form.Group>

                        </Row>

                        <div className='bgOrangeClr fw-bold p-2 text-white mt-5'>
                            Highest Educational Qualification
                        </div>
                        <Row className="mb-5 align-items-center">
                            <Form.Group as={Col} md="2 mt-4" controlId="validationCustom01">
                                <Form.Label>Course Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Course Name"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4 mt-4" controlId="validationCustom01">
                                <Form.Label>School Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="School Name"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="1 mt-4" controlId="validationCustom02">
                                <Form.Label>Year</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="Year"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="2 mt-4" controlId="validationCustom03">
                                <Form.Label>Obtain Mark</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Obtain Mark"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="2 mt-4" controlId="validationCustom03">
                                <Form.Label>Total Mark</Form.Label>
                                <Form.Control
                                    required
                                    type="number"
                                    placeholder="Total Mark"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="1 mt-4" controlId="validationCustom03">
                                <Form.Label>Per</Form.Label>
                                <Form.Control
                                    required
                                    type="number"
                                    placeholder="%"
                                />
                            </Form.Group>
                        </Row>
                        <div className='bgOrangeClr fw-bold p-2 text-white mt-5'>
                            Upload Documents
                        </div>
                        <Row className='mt-3 mb-5'>
                            <Form.Group as={Col} lg="3" xs="6 mt-4 text-center" controlId="validationCustom03">
                                {!formData.marksheet &&
                                    <label className={`${formData.checkFiles === true ? "border-danger text-danger" : ""} regPhoto`}>
                                        Upload Marksheet
                                        <Form.Control
                                            required
                                            type="file"
                                            className='d-none'
                                            onChange={(e) => { setFormData({ ...formData, marksheet: e.target.files[0] }) }}
                                        />
                                    </label>
                                }
                                {formData.marksheet &&
                                    <div className='position-relative'>
                                        <span className='isPhotoX' onClick={() => setFormData({ ...formData, marksheet: "" })}>&#10006;</span>
                                        <img src={URL.createObjectURL(formData.marksheet)} alt="" className='isPhoto' />
                                    </div>}
                            </Form.Group>
                            <Form.Group as={Col} lg="3" xs="6 mt-4 text-center" controlId="validationCustom03">
                                {!formData.photo &&
                                    <label className={`${formData.checkFiles === true ? "border-danger text-danger" : ""} regPhoto`}>
                                        Upload Photo
                                        <Form.Control
                                            required
                                            type="file"
                                            className='d-none'
                                            onChange={(e) => { setFormData({ ...formData, photo: e.target.files[0] }) }}
                                        />
                                    </label>
                                }
                                {formData.photo &&
                                    <div className='position-relative'>
                                        <span className='isPhotoX' onClick={() => setFormData({ ...formData, photo: "" })}>&#10006;</span>
                                        <img src={URL.createObjectURL(formData.photo)} alt="" className='isPhoto' />
                                    </div>}
                            </Form.Group>
                            <Form.Group as={Col} lg="3" xs="6 mt-4 text-center" controlId="validationCustom03">
                                {!formData.sign &&
                                    <label className={`${formData.checkFiles === true ? "border-danger text-danger" : ""} regPhoto`}>
                                        Upload Sign
                                        <Form.Control
                                            required
                                            type="file"
                                            className='d-none'
                                            onChange={(e) => { setFormData({ ...formData, sign: e.target.files[0] }) }}
                                        />
                                    </label>
                                }
                                {formData.sign &&
                                    <div className='position-relative'>
                                        <span className='isPhotoX' onClick={() => setFormData({ ...formData, sign: "" })}>&#10006;</span>
                                        <img src={URL.createObjectURL(formData.sign)} alt="" className='isPhoto' />
                                    </div>}
                            </Form.Group>

                            <Form.Group as={Col} lg="3" xs="6 mt-4 text-center" controlId="validationCustom03">
                                {!formData.thumb &&
                                    <label className={`${formData.checkFiles === true ? "border-danger text-danger" : ""} regPhoto`}>
                                        Upload Thumb
                                        <Form.Control
                                            required
                                            type="file"
                                            className='d-none'
                                            onChange={(e) => { setFormData({ ...formData, thumb: e.target.files[0] }) }}
                                        />
                                    </label>
                                }
                                {formData.thumb &&
                                    <div className='position-relative'>
                                        <span className='isPhotoX' onClick={() => setFormData({ ...formData, thumb: "" })}>&#10006;</span>
                                        <img src={URL.createObjectURL(formData.thumb)} alt="" className='isPhoto' />
                                    </div>}
                            </Form.Group>
                        </Row>


                        <Form.Group className="my-5">
                            <Form.Check
                                required
                                label="I, hereby declare that the particulars submitted by me in the online examination application form are true to the best of my knowledge and belief."
                                feedbackType="invalid"
                            />
                        </Form.Group>
                        <div className='text-center'>
                            <button className='contactBtn my-3 w-auto px-5 fw-bold'>Submit</button>
                        </div>
                    </Form>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Registration