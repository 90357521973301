import React from 'react'
import { Container, Form, Table } from 'react-bootstrap'

const StudentResult = () => {
    return (
        <React.Fragment>
            <div className='bggray pb-5'>
                <Container>
                    <h1 className='main_heading'>Results</h1>
                </Container>
                <Container>
                    <Table bordered>
                        <thead>
                            <tr>
                            <th>Name</th>
                            <th>Class</th>
                            <th>Year</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <Form.Group>
                                        <Form.Select>
                                            <option>Select Name</option>
                                            <option>ABCD</option>
                                            <option>XYZ</option>
                                        </Form.Select>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group>
                                        <Form.Select>
                                            <option>Select Class</option>
                                            <option>ABCD</option>
                                            <option>XYZ</option>
                                        </Form.Select>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group>
                                        <Form.Select>
                                            <option>Select Year</option>
                                            <option>2021</option>
                                            <option>2022</option>
                                        </Form.Select>
                                    </Form.Group>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3} className="text-center">
                                <button className='contactBtn my-3 w-auto px-5 fw-bold ms-4'>Download</button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default StudentResult