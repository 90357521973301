import React, { useState } from 'react'
import { Card, Carousel, Col, Container, Nav, Row, Tab, Table } from 'react-bootstrap'
import { FaChevronRight, FaFacebook, FaInstagram, FaQuoteRight, FaTwitter } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const About = () => {
  const [key, setKey] = useState('About');
  const aboutData = [
    {
      src: "/assets/ourvision.png",
      heading: "Our Vision",
      msg: "Note that the tours are booked well in advance and only a certain number of parents can be accommodated on each tour on first come first serve basis. Also it should be noted that the tour does not guarantee that the child will be accepted in the school as this depends on the availability and also the eligibility of the child."
    },
    {
      src: "/assets/ourmission.png",
      heading: "Our Mission",
      msg: "Note that the tours are booked well in advance and only a certain number of parents can be accommodated on each tour on first come first serve basis. Also it should be noted that the tour does not guarantee that the child will be accepted in the school as this depends on the availability and also the eligibility of the child."
    },
    {
      src: "/assets/corebeliefs.png",
      heading: "Core Beliefs",
      msg: "Note that the tours are booked well in advance and only a certain number of parents can be accommodated on each tour on first come first serve basis. Also it should be noted that the tour does not guarantee that the child will be accepted in the school as this depends on the availability and also the eligibility of the child."
    },
    {
      src: "/assets/pupilcare.png",
      heading: "Pupil Care",
      msg: "Note that the tours are booked well in advance and only a certain number of parents can be accommodated on each tour on first come first serve basis. Also it should be noted that the tour does not guarantee that the child will be accepted in the school as this depends on the availability and also the eligibility of the child."
    },
  ]

  const teachers = [
    {
      src: "/assets/teacher1.png",
      name: "Deborah David",
      designation: "Head",
      specialization: "English",
      qualification: "Phd",
      experience: "12",
      email: "email@acheiversguru.in"
    },
    {
      src: "/assets/teacher1.png",
      name: "Deborah David",
      designation: "Head",
      specialization: "English",
      qualification: "Phd",
      experience: "12",
      email: "email@acheiversguru.in"
    },
    {
      src: "/assets/teacher1.png",
      name: "Deborah David",
      designation: "Head",
      specialization: "English",
      qualification: "Phd",
      experience: "12",
      email: "email@acheiversguru.in"
    },
    {
      src: "/assets/teacher1.png",
      name: "Deborah David",
      designation: "Head",
      specialization: "English",
      qualification: "Phd",
      experience: "12",
      email: "email@acheiversguru.in"
    },
  ]

  const feesData = [
    {
      cName: "1st class",
      duration: "1 Year",
      intake: "60",
      fees: 3000
    },
    {
      cName: "2nd class",
      duration: "1 Year",
      intake: "60",
      fees: 3000
    },
    {
      cName: "3rd class",
      duration: "1 Year",
      intake: "60",
      fees: 3000
    },
  ]
  return (
    <React.Fragment>
      <div className='bggray'>
        <Container>
          <h1 className='main_heading'>About</h1>
        </Container>
      </div>
      <div className='border-bottom pb-1'>
        <Container className='align-items-center'>
          <Link className='text-decoration-none homeBreadcrumb' to="/">Home</Link>
          <FaChevronRight size={12} className="mx-2" color='#999' />
          <label className='mouse homeBreadcrumb' onClick={() => setKey("About")}>About</label>
          {key === "About" ? "" : <>
            <FaChevronRight size={12} className="mx-2" color='#999' />
            <Link className='text-decoration-none activeBerdcrumb' to="/about">{key}</Link>
          </>
          }
        </Container>
      </div>
      <Container className='mb-4'>
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="Admissions"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Row>
            <Col lg={3} className="mt-4">
              <Nav className="border-top border-bottom flex-column">
                <Nav.Item className=' border-bottom'>
                  <Nav.Link className='text-dark tabBG' eventKey="About">About</Nav.Link>
                </Nav.Item>
                <Nav.Item className=' border-bottom'>
                  <Nav.Link className='text-dark tabBG' eventKey="Admissions">Admissions</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className='text-dark tabBG' eventKey="Our Staff">Our Staff</Nav.Link>
                </Nav.Item>
                <Nav.Item className='border-bottom border-top'>
                  <Nav.Link className='text-dark tabBG' eventKey="Principal's Message">Principal's Message</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className='text-dark tabBG' eventKey="School Fees">School Fees</Nav.Link>
                </Nav.Item>
              </Nav>
              <div className='border mt-3'>
                <Carousel indicators={false}>
                  <Carousel.Item className='tw_center p-2'>
                    <img
                      className="d-block rounded-circle mt-2"
                      src="/assets/news1.jpeg"
                      alt="First slide"
                      height={70}
                      width={70}
                    />
                    <h3 className='mt-3 orangeClr'><FaQuoteRight /></h3>
                    <div className='mt-3'>
                      <h3>Achievers School</h3>
                      <p className='px-3'>I called School Time and my husband and son came with me for the tour. We felt the magic immediately.</p>
                      <div>Alexa fon Tobel</div>
                      <div>CEO</div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item className='tw_center p-2'>
                    <img
                      className="d-block rounded-circle mt-2"
                      src="/assets/news1.jpeg"
                      alt="First slide"
                      height={70}
                      width={70}
                    />
                    <h3 className='mt-3 orangeClr'><FaQuoteRight /></h3>
                    <div className='mt-3'>
                      <h3>Worthwhile School</h3>
                      <p className='px-3'>I called School Time and my husband and son came with me for the tour. We felt the magic immediately.</p>
                      <div>Alexa fon Tobel</div>
                      <div>CEO</div>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
            </Col>
            <Col lg={9}>
              <Tab.Content>
                <Tab.Pane eventKey="About">
                  <Row className='mx-0'>
                    {aboutData.map((item, index) =>
                      <Col key={index} sm="6 mt-4">
                        <div className='border'>
                        <img src={item.src} alt={item.heading} className="w-100 mb-2" />
                        <h3 className='px-2'>{item.heading}</h3>
                        <p className='px-2 text_justify'>{item.msg}</p>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Tab.Pane>

                <Tab.Pane eventKey="Our Staff">
                  <Row xs={1} sm={2} lg={3} className="g-4 mt-1">
                    {teachers.map((item, idx) => (
                      <Col key={idx}>
                        <Card>
                          <Card.Img variant="top" src={item.src} />
                          <Card.Body>
                            <Card.Title>{item.name}</Card.Title>
                            <Card.Text className='mb-1'>
                              {item.designation}
                            </Card.Text>
                            <Card.Text>
                              <div className='d-flex'>
                                <div className='w115'>Specialization</div>
                                <span className='me-3'>:</span>
                                {item.specialization}
                              </div>
                              <div className='d-flex'>
                                <div className='w115'>Qualification</div>
                                <span className='me-3'>:</span>
                                {item.qualification}
                              </div>
                              <div className='d-flex'>
                                <div className='w115'>Experience</div>
                                <span className='me-3'>:</span>
                                {item.experience}+ Years
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Tab.Pane>

                <Tab.Pane eventKey="Principal's Message">
                  <Row className='mx-0'>
                    <Col xl={6} className='mt-4'>
                      <div className='border p-4 h520 mutedClr'>
                        <h3 className='text-center'>Welcome To Acheivers School</h3>
                        <p className='px-4 m-1 text_justify'>
                          Thank you for visiting Acheivers School, It was setup on 6th July 2009 with goal to serve
                          computers technology. We have various Courses such as Certification Course, Short Terms Course,
                          Diploma Course and PG Diploma Course, programming language courses, software skills courses and
                          team of experts who teaches student in modern technique and providing live training projects to
                          students for better understanding. We also have top-level labs which are spacious and well equipped
                          with latest technology and trained students professionally by organize special classes for Gd's
                          and spoken English. Acheivers School also providing top level placements to our students
                          on corporate level. We invite you for achieving your career, by excellent training, experts of team,
                          impressive learning skills.
                        </p>
                      </div>
                    </Col>
                    <Col xl={6} className='mt-4 text-center'>
                      <div className='border p-4 h520 mutedClr'>
                        <h3 className='mb-5'>Director Message</h3>
                        <p className='px-4'>
                          <img src='/assets/vijaysir.jpeg' alt='Director' className='rounded-circle' />
                          <p className='mt-5'>
                            "The aim of education is to acquire knowledge, not only of facts, but of values.
                            As we move towards becoming a developed nation"
                          </p>
                          <a href='https://facebook.com'>
                            <FaFacebook />
                          </a>
                          <a href='https://twitter.com'>
                            <FaTwitter className='mx-2' />
                          </a>
                          <a href='https://instagram.com'>
                            <FaInstagram />
                          </a>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Tab.Pane>

                <Tab.Pane eventKey="School Fees">
                  <Table striped bordered hover className='mt-4 feeTableH'>
                    <thead>
                      <tr className='text-center'>
                        <th>S. No.</th>
                        <th>Name of Course</th>
                        <th>Duration of Course</th>
                        <th>Intake</th>
                        <th>Fees</th>
                      </tr>
                    </thead>
                    <tbody>
                      {feesData.map((item, index)=>
                      <tr key={index}  className='text-center'>
                        <td>{index+1}</td>
                        <td>{item.cName}</td>
                        <td>{item.duration}</td>
                        <td>{item.intake}</td>
                        <td>Rs. {item.fees} /-</td>
                      </tr>
                      )}
                    </tbody>
                  </Table>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </React.Fragment>
  )
}

export default About