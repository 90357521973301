import React, { useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaMobileAlt, FaTwitter, FaWhatsapp } from 'react-icons/fa'
import { ImCompass2 } from 'react-icons/im'
// import { MapContainer } from '../components/MapContainer'

const Contact = () => {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  return (
    <React.Fragment>
      <div className='bggray'>
        <Container>
          <h1 className='main_heading'>Contact Us</h1>
        </Container>
      </div>
      <Container >
        <Row className='mb-4'>
          <Col lg="3" md="6 mt-4 text-center">
            <div className='border bg-white py-5 px-4 contact_box'>
              <FaMobileAlt size="100" className='mb-5' />
              <h5 className='my-4'>Contact Our Team</h5>
              <h6 className='fw-bold'>Call: </h6>
              <p>
                <a href="tel:+918888888888" className='text-decoration-none text-dark'>+91 8888888888</a>
              </p>
              <h6 className='fw-bold'>Email: </h6>
              <p>
                <a href="mailto:contact@achiversguru.in" className='text-decoration-none text-dark'>
                  contact@achiversguru.in
                </a>
              </p>

              <h6 className='fw-bold'>Social Media: </h6>

              <a href="/" className='text-decoration-none text-dark'>
                <FaFacebookF className="contact_SocialIcon csFa" />
              </a>
              <a href="/" className='text-decoration-none text-dark'>
                <FaTwitter className="contact_SocialIcon csTa mx-2" />
              </a>
              <a href="/" className='text-decoration-none text-dark'>
                <FaLinkedinIn className="contact_SocialIcon csLi" />
              </a>
              <a href="/" className='text-decoration-none text-dark'>
                <FaInstagram className="contact_SocialIcon csIg mx-2" />
              </a>
              <a href="/" className='text-decoration-none text-dark'>
                <FaWhatsapp className="contact_SocialIcon csWa mx-2" />
              </a>
            </div>
          </Col>
          <Col lg="3" md="6 mt-4 text-center ">
            <div className='border bg-white py-5 px-4 contact_box '>
              <ImCompass2 size="100" className='mb-5' />
              <h5 className='my-4'>Visit Our Office</h5>
              <h6 className='fw-bold'>Address: </h6>

              <p className=''>
                Mohammadi Kheri, Neelam Takies, Lakhimpur Kheri-262804, Uttar Pradesh, India
              </p>

              <button className='contactBtn contactBtnMt'>View On Map »</button>

            </div>
          </Col>
          <Col lg="6" md="12 mt-4">
            <div className='border bg-white py-1 px-4 contact_box'>
              <h3 className='mb-4 mt-3 text-center'>Send us a message!</h3>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="validationCustom01" className='mb-3'>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter Your Name"
                  />
                </Form.Group>
                <Form.Group controlId="validationCustom01" className='mb-3'>
                  <Form.Control
                    required
                    type="email"
                    placeholder="Enter Your Email"
                  />
                </Form.Group>
                <Form.Group controlId="validationCustom01" className='mb-3'>
                  <Form.Control
                    required
                    type="Number"
                    placeholder="Enter Your Phone Number"
                  />
                </Form.Group>
                <Form.Group controlId="validationCustom01" className='mb-3'>
                  <Form.Control
                    required
                    as="textarea"
                    rows={3}
                    placeholder="Write Your Message"
                  />
                </Form.Group>
                <Form.Group controlId="validationCustom01" className="mb-3">
                  <Form.Check
                    required
                    label="I consent to being contacted via telephone and/or email and I consent to my data being stored in accordance with Indian GDPR regulations and agree to the privacy policy, terms &amp; conditions."
                    feedbackType="invalid"
                  />
                </Form.Group>
                <button className='contactBtn my-3'>Submit</button>
              </Form>
            </div>
          </Col>
        </Row>
        <h1 className='main_heading'>VISIT OUR OFFICE</h1>
        {/* <MapContainer /> */}

      </Container>
    </React.Fragment>
  )
}

export default Contact